import React, { memo } from 'react';

import { default as SharedLayout } from '@on-haiti/shared-ui/layout';
import { genericMetadataDolin } from '@on-haiti/shared-ui/data';
import { populateMetadata } from '@on-haiti/shared-ui/utils';
import { graphql, useStaticQuery } from 'gatsby';
import { FooterContainer, HeaderContainer } from '../components';
import siteMetadata from '../../metadata';

const useSiteMetadata = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `,
  );
  return site.siteMetadata;
};

function Layout(props) {
  const { title } = useSiteMetadata();
  populateMetadata(genericMetadataDolin, siteMetadata);

  return (
    <SharedLayout
      metadata={genericMetadataDolin}
      footer={<FooterContainer metadata={siteMetadata.footers} />}
      header={<HeaderContainer metadata={siteMetadata.headers} />}
      title={siteMetadata?.siteData?.agency}
      {...props}
    />
  );
}

export default memo(Layout);
