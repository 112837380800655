export default {
  headers: [
    {
      id: 'mini-header',
      logo: {
        alt: 'logo',
        src: 'https://si-homelight.s3.amazonaws.com/sites/dolin/logo.png',
        url: 'https://www.gabbytaylorgroup.com/',
      },
      breakpoint: 992,
      desktopMenu: {
        type: 'default', // default, split, center, single, one-offs
        disableOnHoverMenuExpand: false,
        logo: {
          show: false,
          reverseOrder: false,
          containerClassName: '',
          imageClassName: '',
        },
        headerClassName: 'on-dolin-mini-header',
        headerSectionsClassName: '',
        headerClassNameOverride: '',
        headerSectionsClassNameOverride: '',
        itemClassName: '',
        menuClassName: '',
        menuLabelActiveClassName: '',
        menuItemClassName: '',
        subMenuClassName: '',
        subMenuLabelActiveClassName: '',
        subMenuItemClassName: '',
        items: [
          {
            id: 'login-item',
            content: 'Login / Register',
            url: 'https://www.gabbytaylorgroup.com/property-search/property-tracker/',
          },
          {
            id: 'phone-item',
            url: 'tel:+19093288797',
            iconSet: 'fa',
            className: 'phone', // on-dolin-desktop-phone-item',
            content: '(909) 328-8797',
          },
        ],
      },
      mobileMenu: {
        logo: {
          show: false,
          containerClassName: '',
          imageClassName: '',
        },
        type: 'mobile-default', // mobile-default, mobile-reversed, mobile-center
        menuOpener: {
          type: 'hamburger',
          className: '',
          hide: true,
          reverse: false,
        },
        headerClassName: 'on-dolin-mini-header-mobile',
        headerSectionsClassName: '',
        headerClassNameOverride: '',
        headerSectionsClassNameOverride: '',
        mobileMenuClassName: '',
        itemClassName: 'on-dolin-mini-item',
        menuClassName: '',
        menuLabelActiveClassName: '',
        menuItemClassName: '',
        subMenuClassName: '',
        subMenuItemClassName: '',
        headerItems: [
          {
            id: 'login-item',
            content: 'Login / Register',
            url: 'https://www.gabbytaylorgroup.com/property-search/property-tracker/',
          },
          {
            id: 'phone-item',
            url: 'tel:+19093288797',
            iconSet: 'fa',
            className: 'phone', // on-dolin-desktop-phone-item',
            content: '(909) 328-8797',
          },
        ],
        items: [],
      },
    },
    {
      id: 'mainHeader',
      logo: {
        alt: 'logo',
        src: 'https://si-homelight.s3.amazonaws.com/sites/dolin/logo.png',
        url: 'https://www.gabbytaylorgroup.com/',
      },
      breakpoint: 992,
      desktopMenu: {
        type: 'default', // default, split, center, single, one-offs
        disableOnHoverMenuExpand: false,
        logo: {
          show: true,
          reverseOrder: false,
          containerClassName: 'on-dolin-logo-container',
          imageClassName: 'on-dolin-logo',
        },
        headerClassName: 'on-dolin-desktop-header',
        headerSectionsClassName: 'on-dolin-desktop-header-section',
        headerClassNameOverride: '',
        headerSectionsClassNameOverride: '',
        itemClassName: 'on-dolin-desktop-item',
        menuClassName: 'on-dolin-menu',
        menuLabelActiveClassName: 'on-dolin-menu-active',
        menuItemClassName: 'on-dolin-menu-item',
        subMenuClassName: '',
        subMenuLabelActiveClassName: '',
        subMenuItemClassName: '',
        items: [
          {
            id: 'search-item',
            content: 'SEARCH',
            url: 'https://www.gabbytaylorgroup.com/property-search/search-form/',
            menuItems: [
              {
                id: 'search-item-advanced',
                content: 'Advanced Search',
                url: 'https://www.gabbytaylorgroup.com/property-search/search-form/',
              },
              {
                id: 'search-item-map',
                content: 'Search By Map',
                url: 'https://www.gabbytaylorgroup.com/property-search/results/?searchtype=3',
              },
              {
                id: 'search-item-tracker',
                content: 'Property Tracker',
                url: 'https://www.gabbytaylorgroup.com/property-search/property-tracker/',
              },
            ],
          },
          {
            id: 'redlands-item',
            content: 'REDLANDS AREA',
            url: 'https://www.gabbytaylorgroup.com/redlands/',
            menuItems: [
              {
                id: 'redlands-item-communities',
                content: 'Redlands Communities',
                url: 'https://www.gabbytaylorgroup.com/redlands-communities/',
              },
              {
                id: 'redlands-item-yucaipa',
                content: 'Yucaipa',
                url: 'https://www.gabbytaylorgroup.com/yucaipa/',
              },
              {
                id: 'redlands-item-chapman',
                content: 'Chapman Heights Yucaipa',
                url: 'https://www.gabbytaylorgroup.com/chapman-heights-yucaipa/',
              },
              {
                id: 'redlands-item-redlands',
                content: 'Redlands',
                url: 'https://www.gabbytaylorgroup.com/redlands/',
              },
              {
                id: 'redlands-item-south',
                content: 'South Redlands',
                url: 'https://www.gabbytaylorgroup.com/south-redlands/',
              },
              {
                id: 'redlands-item-north',
                content: 'North Beach Homes for sale in Yucaipa',
                url: 'https://www.gabbytaylorgroup.com/north-bench-homes-for-sale-in-yucaipa/',
              },
              {
                id: 'redlands-item-calimesa',
                content: 'Calimesa',
                url: 'https://www.gabbytaylorgroup.com/calimesa/',
              },
              {
                id: 'redlands-item-condos',
                content: 'Condos & Townhomes in Redlands',
                url: 'https://www.gabbytaylorgroup.com/condos-townhomes-in-redlands/',
              },
              {
                id: 'redlands-item-east',
                content: 'East Highland Ranch',
                url: 'https://www.gabbytaylorgroup.com/homes-and-real-estate-in-highland/',
              },
              {
                id: 'redlands-item-banning',
                content: 'Banning',
                url: 'https://www.gabbytaylorgroup.com/banning/',
              },
              {
                id: 'redlands-item-historic',
                content: 'Historic Homes',
                url: 'https://www.gabbytaylorgroup.com/historic-homes/',
              },
              {
                id: 'redlands-item-55-communities',
                content: '55+ Communities in Beaumont & Banning',
                url: 'https://www.gabbytaylorgroup.com/55-communities-in-beaumont-banning/',
              },
              {
                id: 'redlands-item-beaumont',
                content: 'Beaumont',
                url: 'https://www.gabbytaylorgroup.com/beaumont/',
              },
              {
                id: 'redlands-item-walk',
                content: 'Walk Zone Loma Linda',
                url: 'https://www.gabbytaylorgroup.com/walk-zone-loma-linda/',
              },
              {
                id: 'redlands-item-homes-beaumont',
                content: 'Homes for Sale in Beaumont and Banning',
                url: 'https://www.gabbytaylorgroup.com/homes-for-sale-in-beaumont-and-banning/',
              },
              {
                id: 'redlands-item-highland',
                content: 'Highland',
                url: 'https://www.gabbytaylorgroup.com/highland/',
              },
              {
                id: 'redlands-item-mid',
                content: 'Mid Century Modern & Ranch Style Homes in Redlands',
                url: 'https://www.gabbytaylorgroup.com/mid-century-modern-and-ranch-style-homes-in-redlands/',
              },
              {
                id: 'redlands-item-west',
                content: 'West of Boulder Ave Highland',
                url: 'https://www.gabbytaylorgroup.com/west-of-boulder-ave-highland/',
              },
              {
                id: 'redlands-item-loma',
                content: 'Loma Linda',
                url: 'https://www.gabbytaylorgroup.com/loma-linda/',
              },
              {
                id: 'redlands-item-homes-loma',
                content: 'Homes & Condos south of Barton Road in Loma Linda',
                url: 'https://www.gabbytaylorgroup.com/homes-condos-south-of-barton-road-in-loma-linda/',
              },
            ],
          },
          {
            id: 'long-beach-item',
            content: 'LONG BEACH AREA',
            url: 'https://www.gabbytaylorgroup.com/contact/long-beach/',
            menuItems: [
              {
                id: 'long-beach-item-communities',
                content: 'Communities in Long Beach',
                url: 'https://www.gabbytaylorgroup.com/contact/communities/',
              },
              {
                id: 'long-beach-item-park-estates',
                content: 'Park Estates',
                url: 'https://www.gabbytaylorgroup.com/contact/park-estates/',
              },
              {
                id: 'long-beach-item-signal',
                content: 'Signal Hill Homes and Real Estate',
                url: 'https://www.gabbytaylorgroup.com/contact/signal-hill-homes-and-real-estate/',
              },
              {
                id: 'long-beach-item-alamitos',
                content: 'Alamitos Heights',
                url: 'https://www.gabbytaylorgroup.com/contact/alamitos-heights/',
              },
              {
                id: 'long-beach-item-alamitos-estate',
                content: 'Alamitos Heights Real Estate Homes',
                url: 'https://www.gabbytaylorgroup.com/contact/alamitos-heights-real-estate-homes-for-sale/',
              },
              {
                id: 'long-beach-item-rose-park',
                content: 'Rose Park Long Beach Homes For Sale',
                url: 'https://www.gabbytaylorgroup.com/contact/rose-park-long-beach-homes-for-sale/',
              },
              {
                id: 'long-beach-item-belmont',
                content: 'Belmont Heights',
                url: 'https://www.gabbytaylorgroup.com/contact/belmont-heights/',
              },
              {
                id: 'long-beach-item-belmont-homes',
                content: 'Belmont Heights Homes',
                url: 'https://www.gabbytaylorgroup.com/contact/belmont-heights-homes-for-sale/',
              },
              {
                id: 'long-beach-item-east',
                content: 'East Village Long Beach',
                url: 'https://www.gabbytaylorgroup.com/contact/east-village-long-beach/',
              },
              {
                id: 'long-beach-item-belmont-shore',
                content: 'Belmont Shore',
                url: 'https://www.gabbytaylorgroup.com/contact/belmont-shore/',
              },
              {
                id: 'long-beach-item-belmont-shore-homes',
                content: 'Belmont Shores and the Peninsula Homes and Real Estate',
                url: 'https://www.gabbytaylorgroup.com/contact/belmont-shores-and-the-peninsula-homes-and-real-estate/',
              },
              {
                id: 'long-beach-item-lakewood',
                content: 'Lakewood Homes and Real Estate',
                url: 'https://www.gabbytaylorgroup.com/contact/',
              },
              {
                id: 'long-beach-item-bluff',
                content: 'Bluff Heights',
                url: 'https://www.gabbytaylorgroup.com/contact/bluff-heights/',
              },
              {
                id: 'long-beach-item-bluff-park-homes',
                content: 'Bluff Park Homes',
                url: 'https://www.gabbytaylorgroup.com/contact/bluff-park-long-beach/',
              },
              {
                id: 'long-beach-item-seal',
                content: 'Seal Beach Homes',
                url: 'https://www.gabbytaylorgroup.com/contact/seal-beach-homes/',
              },
              {
                id: 'long-beach-item-bluff-park',
                content: 'Bluff Park',
                url: 'https://www.gabbytaylorgroup.com/contact/bluff-park/',
              },
              {
                id: 'long-beach-item-bluff-homes',
                content: 'Bluff Heights Homes',
                url: 'https://www.gabbytaylorgroup.com/contact/bluff-heights-homes/',
              },
              {
                id: 'long-beach-item-carroll',
                content: 'Carroll Park Homes in Long Beach',
                url: 'https://www.gabbytaylorgroup.com/contact/carroll-park-homes-in-long-beach/',
              },
              {
                id: 'long-beach-item-long-beach',
                content: 'Long Beach',
                url: 'https://www.gabbytaylorgroup.com/contact/long-beach/',
              },
              {
                id: 'long-beach-item-los-altos-ns',
                content: 'Los Altos North and South Homes',
                url: 'https://www.gabbytaylorgroup.com/contact/los-altos-north-and-south-homes/',
              },
              {
                id: 'long-beach-item-downtown',
                content: 'Apartment and Homes in Downtown Long Beach',
                url: 'https://www.gabbytaylorgroup.com/contact/apartment-and-homes-in-downtown-long-beach/',
              },
              {
                id: 'long-beach-item-los-altos',
                content: 'Los Altos',
                url: 'https://www.gabbytaylorgroup.com/contact/los-altos/',
              },
              {
                id: 'long-beach-item-naples-homes',
                content: 'Naples Homes',
                url: 'https://www.gabbytaylorgroup.com/contact/naples-homes/',
              },
              {
                id: 'long-beach-item-naples',
                content: 'Naples',
                url: 'https://www.gabbytaylorgroup.com/contact/naples/',
              },
              {
                id: 'long-beach-item-park-estates-homes',
                content: 'Park Estates Homes',
                url: 'https://www.gabbytaylorgroup.com/contact/park-estates-homes/',
              },
            ],
          },
          {
            id: 'buyers-item',
            content: 'BUYERS',
            url: 'https://www.gabbytaylorgroup.com/buyers/',
            menuItems: [
              {
                id: 'buyers-item-long',
                content: 'Buyers in Long Beach',
                url: 'https://www.gabbytaylorgroup.com/buyers/',
              },
              {
                id: 'buyers-item-offer',
                content: 'Making an Offer',
                url: 'https://www.gabbytaylorgroup.com/buyers/making-an-offer/',
              },
              {
                id: 'buyers-item-redlands',
                content: 'Buyers in Redlands',
                url: 'https://www.gabbytaylorgroup.com/buyers/buyers-in-redlands/',
              },
              {
                id: 'buyers-item-escrow',
                content: 'Escrow: Now What?',
                url: 'https://www.gabbytaylorgroup.com/buyers/escrow-now-what/',
              },
              {
                id: 'buyers-item-mortgage',
                content: 'Mortgage Calculator',
                url: 'https://www.gabbytaylorgroup.com/buyers/mortgage-calculator/',
              },
              {
                id: 'buyers-item-closing',
                content: 'What Are Closing Costs?',
                url: 'https://www.gabbytaylorgroup.com/buyers/what-are-closing-costs/',
              },
              {
                id: 'buyers-item-pre-mortgage',
                content: 'Mortgage Pre-Approval',
                url: 'https://www.gabbytaylorgroup.com/buyers/mortgage-pre-approval/',
              },
              {
                id: 'buyers-item-financial',
                content: 'Financial Terms Glossary',
                url: 'https://www.gabbytaylorgroup.com/buyers/financial-terms-glossary/',
              },
              {
                id: 'buyers-item-first',
                content: 'First Time Buyers',
                url: 'https://www.gabbytaylorgroup.com/buyers/first-time-buyers/',
              },
              {
                id: 'buyers-item-personalized',
                content: 'Personalized Home Search',
                url: 'https://www.gabbytaylorgroup.com/buyers/personalized-home-search/',
              },
              {
                id: 'buyers-item-cash-offer',
                content: 'Cash Offer',
                url: 'http://cashoffer.gabbytaylorgroup.com',
              },
            ],
          },
          {
            id: 'sellers-item',
            content: 'SELLERS',
            url: 'https://www.gabbytaylorgroup.com/sellers/',
            menuItems: [
              {
                id: 'sellers-item-long',
                content: 'Sellers in Long Beach',
                url: 'https://www.gabbytaylorgroup.com/sellers/',
              },
              {
                id: 'sellers-item-redlands',
                content: 'Sellers in Redlands',
                url: 'https://www.gabbytaylorgroup.com/sellers/sellers-in-redlands/',
              },
              {
                id: 'sellers-item-pricing',
                content: 'Pricing Your Home',
                url: 'https://www.gabbytaylorgroup.com/sellers/pricing-your-home/',
              },
              {
                id: 'sellers-item-marketing',
                content: 'Marketing Your Home',
                url: 'https://www.gabbytaylorgroup.com/sellers/marketing-your-home/',
              },
              {
                id: 'sellers-item-showing',
                content: 'Showing Your Home',
                url: 'https://www.gabbytaylorgroup.com/sellers/showing-your-home/',
              },
              {
                id: 'sellers-item-adding',
                content: 'Adding Value',
                url: 'https://www.gabbytaylorgroup.com/sellers/adding-value/',
              },
              {
                id: 'sellers-item-free',
                content: 'Free Market Analysis',
                url: 'https://www.gabbytaylorgroup.com/sellers/free-market-analysis/',
              },
              {
                id: 'sellers-item-trade-in',
                content: 'Trade In',
                url: 'http://trade-in.gabbytaylorgroup.com',
              },
            ],
          },
          {
            id: 'about-item',
            content: 'ABOUT',
            url: 'https://www.gabbytaylorgroup.com/about/',
            menuItems: [
              {
                id: 'about-item-about',
                content: 'About',
                url: 'https://www.gabbytaylorgroup.com/about/',
              },
              {
                id: 'about-item-contact',
                content: 'Contact Us',
                url: 'https://www.gabbytaylorgroup.com/about/contact-us/',
              },
            ],
          },
        ],
      },
      mobileMenu: {
        logo: {
          show: true,
          containerClassName: '',
          imageClassName: 'on-dolin-logo-mobile',
        },
        type: 'mobile-default', // mobile-default, mobile-reversed, mobile-center
        menuOpener: {
          type: 'hamburger',
          className: 'on-dolin-hamburger',
          hide: false,
          reverse: false,
        },
        headerClassName: 'on-dolin-mobile-header',
        headerSectionsClassName: '',
        headerClassNameOverride: '',
        headerSectionsClassNameOverride: '',
        mobileMenuClassName: 'on-dolin-mobile-menu',
        itemClassName: 'on-dolin-mobile-item',
        menuClassName: 'on-dolin-mobile-folder',
        menuLabelActiveClassName: 'on-dolin-folder-active',
        menuItemClassName: 'on-dolin-mobile-folder-item',
        subMenuClassName: '',
        subMenuItemClassName: '',
        headerItems: [],
        items: [
          {
            id: 'menu-logo-item',
            image: {
              src: 'https://si-homelight.s3.amazonaws.com/sites/dolin/logo.png',
              alt: 'logo',
            },
            url: 'https://www.gabbytaylorgroup.com/',
          },
          {
            id: 'menu-phone-item',
            iconSet: 'fa',
            className: 'phone',
            url: 'tel:+19093288797',
            content: '(909) 328-8797',
          },
          {
            id: 'menu-home-item',
            content: 'Home',
            url: 'https://www.gabbytaylorgroup.com/',
          },
          {
            id: 'search-item',
            content: 'Search',
            menuItems: [
              {
                id: 'search-item-advanced',
                content: 'Advanced Search',
                url: 'https://www.gabbytaylorgroup.com/property-search/search-form/',
              },
              {
                id: 'search-item-map',
                content: 'Search By Map',
                url: 'https://www.gabbytaylorgroup.com/property-search/results/?searchtype=3',
              },
              {
                id: 'search-item-tracker',
                content: 'Property Tracker',
                url: 'https://www.gabbytaylorgroup.com/property-search/property-tracker/',
              },
            ],
          },
          {
            id: 'redlands-item',
            content: 'Redlands Area',
            menuItems: [
              {
                id: 'redlands-item-communities',
                content: 'Redlands Communities',
                url: 'https://www.gabbytaylorgroup.com/redlands-communities/',
              },
              {
                id: 'redlands-item-yucaipa',
                content: 'Yucaipa',
                url: 'https://www.gabbytaylorgroup.com/yucaipa/',
              },
              {
                id: 'redlands-item-chapman',
                content: 'Chapman Heights Yucaipa',
                url: 'https://www.gabbytaylorgroup.com/chapman-heights-yucaipa/',
              },
              {
                id: 'redlands-item-redlands',
                content: 'Redlands',
                url: 'https://www.gabbytaylorgroup.com/redlands/',
              },
              {
                id: 'redlands-item-south',
                content: 'South Redlands',
                url: 'https://www.gabbytaylorgroup.com/south-redlands/',
              },
              {
                id: 'redlands-item-north',
                content: 'North Beach Homes for sale in Yucaipa',
                url: 'https://www.gabbytaylorgroup.com/north-bench-homes-for-sale-in-yucaipa/',
              },
              {
                id: 'redlands-item-calimesa',
                content: 'Calimesa',
                url: 'https://www.gabbytaylorgroup.com/calimesa/',
              },
              {
                id: 'redlands-item-condos',
                content: 'Condos & Townhomes in Redlands',
                url: 'https://www.gabbytaylorgroup.com/condos-townhomes-in-redlands/',
              },
              {
                id: 'redlands-item-east',
                content: 'East Highland Ranch',
                url: 'https://www.gabbytaylorgroup.com/homes-and-real-estate-in-highland/',
              },
              {
                id: 'redlands-item-banning',
                content: 'Banning',
                url: 'https://www.gabbytaylorgroup.com/banning/',
              },
              {
                id: 'redlands-item-historic',
                content: 'Historic Homes',
                url: 'https://www.gabbytaylorgroup.com/historic-homes/',
              },
              {
                id: 'redlands-item-55-communities',
                content: '55+ Communities in Beaumont & Banning',
                url: 'https://www.gabbytaylorgroup.com/55-communities-in-beaumont-banning/',
              },
              {
                id: 'redlands-item-beaumont',
                content: 'Beaumont',
                url: 'https://www.gabbytaylorgroup.com/beaumont/',
              },
              {
                id: 'redlands-item-walk',
                content: 'Walk Zone Loma Linda',
                url: 'https://www.gabbytaylorgroup.com/walk-zone-loma-linda/',
              },
              {
                id: 'redlands-item-homes-beaumont',
                content: 'Homes for Sale in Beaumont and Banning',
                url: 'https://www.gabbytaylorgroup.com/homes-for-sale-in-beaumont-and-banning/',
              },
              {
                id: 'redlands-item-highland',
                content: 'Highland',
                url: 'https://www.gabbytaylorgroup.com/highland/',
              },
              {
                id: 'redlands-item-mid',
                content: 'Mid Century Modern & Ranch Style Homes in Redlands',
                url: 'https://www.gabbytaylorgroup.com/mid-century-modern-and-ranch-style-homes-in-redlands/',
              },
              {
                id: 'redlands-item-west',
                content: 'West of Boulder Ave Highland',
                url: 'https://www.gabbytaylorgroup.com/west-of-boulder-ave-highland/',
              },
              {
                id: 'redlands-item-loma',
                content: 'Loma Linda',
                url: 'https://www.gabbytaylorgroup.com/loma-linda/',
              },
              {
                id: 'redlands-item-homes-loma',
                content: 'Homes & Condos south of Barton Road in Loma Linda',
                url: 'https://www.gabbytaylorgroup.com/homes-condos-south-of-barton-road-in-loma-linda/',
              },
            ],
          },
          {
            id: 'long-beach-item',
            content: 'Long Beach Area',
            menuItems: [
              {
                id: 'long-beach-item-communities',
                content: 'Communities in Long Beach',
                url: 'https://www.gabbytaylorgroup.com/contact/communities/',
              },
              {
                id: 'long-beach-item-park-estates',
                content: 'Park Estates',
                url: 'https://www.gabbytaylorgroup.com/contact/park-estates/',
              },
              {
                id: 'long-beach-item-signal',
                content: 'Signal Hill Homes and Real Estate',
                url: 'https://www.gabbytaylorgroup.com/contact/signal-hill-homes-and-real-estate/',
              },
              {
                id: 'long-beach-item-alamitos',
                content: 'Alamitos Heights',
                url: 'https://www.gabbytaylorgroup.com/contact/alamitos-heights/',
              },
              {
                id: 'long-beach-item-alamitos-estate',
                content: 'Alamitos Heights Real Estate Homes',
                url: 'https://www.gabbytaylorgroup.com/contact/alamitos-heights-real-estate-homes-for-sale/',
              },
              {
                id: 'long-beach-item-rose-park',
                content: 'Rose Park Long Beach Homes For Sale',
                url: 'https://www.gabbytaylorgroup.com/contact/rose-park-long-beach-homes-for-sale/',
              },
              {
                id: 'long-beach-item-belmont',
                content: 'Belmont Heights',
                url: 'https://www.gabbytaylorgroup.com/contact/belmont-heights/',
              },
              {
                id: 'long-beach-item-belmont-homes',
                content: 'Belmont Heights Homes',
                url: 'https://www.gabbytaylorgroup.com/contact/belmont-heights-homes-for-sale/',
              },
              {
                id: 'long-beach-item-east',
                content: 'East Village Long Beach',
                url: 'https://www.gabbytaylorgroup.com/contact/east-village-long-beach/',
              },
              {
                id: 'long-beach-item-belmont-shore',
                content: 'Belmont Shore',
                url: 'https://www.gabbytaylorgroup.com/contact/belmont-shore/',
              },
              {
                id: 'long-beach-item-belmont-shore-homes',
                content: 'Belmont Shores and the Peninsula Homes and Real Estate',
                url: 'https://www.gabbytaylorgroup.com/contact/belmont-shores-and-the-peninsula-homes-and-real-estate/',
              },
              {
                id: 'long-beach-item-lakewood',
                content: 'Lakewood Homes and Real Estate',
                url: 'https://www.gabbytaylorgroup.com/contact/',
              },
              {
                id: 'long-beach-item-bluff',
                content: 'Bluff Heights',
                url: 'https://www.gabbytaylorgroup.com/contact/bluff-heights/',
              },
              {
                id: 'long-beach-item-bluff-park-homes',
                content: 'Bluff Park Homes',
                url: 'https://www.gabbytaylorgroup.com/contact/bluff-park-long-beach/',
              },
              {
                id: 'long-beach-item-seal',
                content: 'Seal Beach Homes',
                url: 'https://www.gabbytaylorgroup.com/contact/seal-beach-homes/',
              },
              {
                id: 'long-beach-item-bluff-park',
                content: 'Bluff Park',
                url: 'https://www.gabbytaylorgroup.com/contact/bluff-park/',
              },
              {
                id: 'long-beach-item-bluff-homes',
                content: 'Bluff Heights Homes',
                url: 'https://www.gabbytaylorgroup.com/contact/bluff-heights-homes/',
              },
              {
                id: 'long-beach-item-carroll',
                content: 'Carroll Park Homes in Long Beach',
                url: 'https://www.gabbytaylorgroup.com/contact/carroll-park-homes-in-long-beach/',
              },
              {
                id: 'long-beach-item-long-beach',
                content: 'Long Beach',
                url: 'https://www.gabbytaylorgroup.com/contact/long-beach/',
              },
              {
                id: 'long-beach-item-los-altos-ns',
                content: 'Los Altos North and South Homes',
                url: 'https://www.gabbytaylorgroup.com/contact/los-altos-north-and-south-homes/',
              },
              {
                id: 'long-beach-item-downtown',
                content: 'Apartment and Homes in Downtown Long Beach',
                url: 'https://www.gabbytaylorgroup.com/contact/apartment-and-homes-in-downtown-long-beach/',
              },
              {
                id: 'long-beach-item-los-altos',
                content: 'Los Altos',
                url: 'https://www.gabbytaylorgroup.com/contact/los-altos/',
              },
              {
                id: 'long-beach-item-naples-homes',
                content: 'Naples Homes',
                url: 'https://www.gabbytaylorgroup.com/contact/naples-homes/',
              },
              {
                id: 'long-beach-item-naples',
                content: 'Naples',
                url: 'https://www.gabbytaylorgroup.com/contact/naples/',
              },
              {
                id: 'long-beach-item-park-estates-homes',
                content: 'Park Estates Homes',
                url: 'https://www.gabbytaylorgroup.com/contact/park-estates-homes/',
              },
            ],
          },
          {
            id: 'buyers-item',
            content: 'Buyers',
            menuItems: [
              {
                id: 'buyers-item-long',
                content: 'Buyers in Long Beach',
                url: 'https://www.gabbytaylorgroup.com/buyers/',
              },
              {
                id: 'buyers-item-offer',
                content: 'Making an Offer',
                url: 'https://www.gabbytaylorgroup.com/buyers/making-an-offer/',
              },
              {
                id: 'buyers-item-redlands',
                content: 'Buyers in Redlands',
                url: 'https://www.gabbytaylorgroup.com/buyers/buyers-in-redlands/',
              },
              {
                id: 'buyers-item-escrow',
                content: 'Escrow: Now What?',
                url: 'https://www.gabbytaylorgroup.com/buyers/escrow-now-what/',
              },
              {
                id: 'buyers-item-mortgage',
                content: 'Mortgage Calculator',
                url: 'https://www.gabbytaylorgroup.com/buyers/mortgage-calculator/',
              },
              {
                id: 'buyers-item-closing',
                content: 'What Are Closing Costs?',
                url: 'https://www.gabbytaylorgroup.com/buyers/what-are-closing-costs/',
              },
              {
                id: 'buyers-item-pre-mortgage',
                content: 'Mortgage Pre-Approval',
                url: 'https://www.gabbytaylorgroup.com/buyers/mortgage-pre-approval/',
              },
              {
                id: 'buyers-item-financial',
                content: 'Financial Terms Glossary',
                url: 'https://www.gabbytaylorgroup.com/buyers/financial-terms-glossary/',
              },
              {
                id: 'buyers-item-first',
                content: 'First Time Buyers',
                url: 'https://www.gabbytaylorgroup.com/buyers/first-time-buyers/',
              },
              {
                id: 'buyers-item-personalized',
                content: 'Personalized Home Search',
                url: 'https://www.gabbytaylorgroup.com/buyers/personalized-home-search/',
              },
              {
                id: 'buyers-item-cash-offer-menu',
                content: 'Cash Offer',
                url: 'http://cashoffer.gabbytaylorgroup.com',
              },
            ],
          },
          {
            id: 'sellers-item',
            content: 'Sellers',
            menuItems: [
              {
                id: 'sellers-item-long',
                content: 'Sellers in Long Beach',
                url: 'https://www.gabbytaylorgroup.com/sellers/',
              },
              {
                id: 'sellers-item-redlands',
                content: 'Sellers in Redlands',
                url: 'https://www.gabbytaylorgroup.com/sellers/sellers-in-redlands/',
              },
              {
                id: 'sellers-item-pricing',
                content: 'Pricing Your Home',
                url: 'https://www.gabbytaylorgroup.com/sellers/pricing-your-home/',
              },
              {
                id: 'sellers-item-marketing',
                content: 'Marketing Your Home',
                url: 'https://www.gabbytaylorgroup.com/sellers/marketing-your-home/',
              },
              {
                id: 'sellers-item-showing',
                content: 'Showing Your Home',
                url: 'https://www.gabbytaylorgroup.com/sellers/showing-your-home/',
              },
              {
                id: 'sellers-item-adding',
                content: 'Adding Value',
                url: 'https://www.gabbytaylorgroup.com/sellers/adding-value/',
              },
              {
                id: 'sellers-item-free',
                content: 'Free Market Analysis',
                url: 'https://www.gabbytaylorgroup.com/sellers/free-market-analysis/',
              },
              {
                id: 'sellers-item-trade-in-menu',
                content: 'Trade In',
                url: 'http://trade-in.gabbytaylorgroup.com',
              },
            ],
          },
          {
            id: 'about-item',
            content: 'About',
            menuItems: [
              {
                id: 'about-item-about',
                content: 'About',
                url: 'https://www.gabbytaylorgroup.com/about/',
              },
              {
                id: 'about-item-contact',
                content: 'Contact Us',
                url: 'https://www.gabbytaylorgroup.com/about/contact-us/',
              },
            ],
          },
          {
            id: 'menu-login-item',
            content: 'Login',
            url: 'https://www.gabbytaylorgroup.com/property-search/property-tracker/',
            className: 'on-dolin-user-items',
          },
          {
            id: 'menu-register-item',
            content: 'Register',
            url: 'https://www.gabbytaylorgroup.com/property-search/property-tracker/',
            className: 'on-dolin-user-items',
          },
          {
            id: 'menu-fb-item',
            iconSet: 'fab',
            className: 'facebook-f',
            url: 'https://www.facebook.com/gabbytaylorgroup',
          },
          {
            id: 'menu-ig-item',
            iconSet: 'fab',
            className: 'instagram',
            url: 'https://www.instagram.com/gabby.mtaylor/?hl=en',
          },
          {
            id: 'menu-linkedin-item',
            iconSet: 'fab',
            className: 'linkedin-in',
            url: 'https://www.linkedin.com/in/gabby-taylor-b69b6a18/',
          },
          {
            id: 'menu-pinterest-item',
            iconSet: 'fab',
            className: 'pinterest-p',
            url: 'https://www.pinterest.com/gabbytaylorgroup/_saved/',
          },
          {
            id: 'menu-yt-item',
            iconSet: 'fab',
            className: 'youtube',
            url: 'https://www.youtube.com/channel/UCmFyfj4XJvNXEy1CcfhRP3A',
          },
        ],
      },
    },
  ],
  footers: [
    {
      id: 'on-dolin-footer-one',
      display: true,
      sectionClassName: 'on-dolin-footer-section-one-container',
      className: 'on-dolin-footer-section',
      columns: [
        {
          id: 'on-dolin-footer-column-one',
          className: '',
          style: {},
          items: [
            {
              id: 'on-dolin-footer-menu',
              className: 'on-dolin-footer-pages-menu xl-between lg-between',
              itemClassName: 'on-dolin-footer-pages-menu-item',
              type: 'menu',
              items: [
                {
                  id: 'footer-search-item',
                  data: 'SEARCH',
                  className: '',
                  url: 'https://www.gabbytaylorgroup.com/property-search/search-form/',
                },
                {
                  id: 'footer-redlands-area-item',
                  data: 'REDLANDS AREA',
                  url: 'https://www.gabbytaylorgroup.com/redlands/',
                },
                {
                  id: 'footer-long-beach-item',
                  data: 'LONG BEACH AREA',
                  url: 'https://www.gabbytaylorgroup.com/contact/long-beach/',
                },
                {
                  id: 'footer-buyers-item',
                  data: 'BUYERS',
                  url: 'https://www.gabbytaylorgroup.com/buyers/',
                },
                {
                  id: 'footer-sellers-item',
                  data: 'SELLERS',
                  url: 'https://www.gabbytaylorgroup.com/sellers/',
                },
                {
                  id: 'footer-about-item',
                  data: 'ABOUT',
                  url: 'https://www.gabbytaylorgroup.com/about/',
                },
                {
                  id: 'footer-login-item',
                  data: 'LOGIN/REGISTER',
                  url: 'https://www.gabbytaylorgroup.com/property-search/property-tracker/',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: 'on-dolin-footer-two',
      display: true,
      sectionClassName: 'on-dolin-footer-section-two-container',
      className: 'on-dolin-footer-section-two',
      columns: [
        {
          id: 'f-2-col-1',
          className: 'col-xl-6',
          style: {},
          items: [
            {
              id: 0,
              type: 'image',
              className: 'on-dolin-footer-logo',
              alt: 'img',
              url: 'https://si-homelight.s3.amazonaws.com/sites/dolin/logo.png',
              linkUrl: 'https://www.gabbytaylorgroup.com/',
              style: { width: '230px' },
            },
          ],
        },
        {
          id: 'f-2-col-2',
          className: 'col-xl-6 on-dolin-footer-socials-column',
          style: {},
          items: [
            {
              id: 'f-2-col-2-socials',
              type: 'socialIcons',
              className: 'on-dolin-footer-socials',
              itemClassName: '',
              style: {},
              items: [
                {
                  id: 'footer-facebook',
                  iconTypeClassName: 'fab',
                  iconClassName: 'facebook-f',
                  className: '',
                  url: 'https://www.facebook.com/gabbytaylorgroup',
                },
                {
                  id: 'footer-instagram',
                  iconTypeClassName: 'fab',
                  iconClassName: 'instagram',
                  className: '',
                  url: 'https://www.instagram.com/gabby.mtaylor/?hl=en',
                },
                {
                  id: 'footer-linkedin',
                  iconTypeClassName: 'fab',
                  iconClassName: 'linkedin-in',
                  className: '',
                  url: 'https://www.linkedin.com/in/gabby-taylor-b69b6a18/',
                },

                {
                  id: 'footer-pinterest',
                  iconTypeClassName: 'fab',
                  iconClassName: 'pinterest',
                  className: '',
                  url: 'https://www.pinterest.com/gabbytaylorgroup/_saved/',
                },
                {
                  id: 'footer-youtube',
                  iconTypeClassName: 'fab',
                  iconClassName: 'youtube',
                  className: '',
                  url: 'https://www.youtube.com/channel/UCmFyfj4XJvNXEy1CcfhRP3A',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: 'on-dolin-footer-three',
      display: true,
      sectionClassName: 'on-dolin-footer-section-three-container',
      className: 'on-dolin-footer-section-three',
      columns: [
        {
          id: 'f-3-col-1',
          className: 'col-xl-3 col-lg-6 col-md-6 col-sm-12',
          items: [
            {
              id: 'f-3-col-1-heading',
              type: 'heading',
              elementType: 'h1',
              className: '',
              data: 'Gabby Taylor group',
            },
            {
              id: 'f-3-col-1-item-1',
              type: 'heading',
              elementType: 'p',
              className: 'mb-0',
              data: 'Gabby Taylor Group',
            },
            {
              id: 'f-3-col-1-item-2',
              type: 'heading',
              elementType: 'p',
              className: 'mb-0',
              data: 'ReMax Advantage',
            },
            {
              id: 'f-3-col-1-item-3',
              type: 'heading',
              elementType: 'p',
              className: 'mb-0',
              data: '611 W. Redlands Blvd',
            },
            {
              id: 'f-3-col-1-item-4',
              type: 'heading',
              elementType: 'p',
              className: 'mb-0',
              data: 'Redlands, CA 92373',
            },
            {
              id: 'f-3-col-1-item-5',
              type: 'itemContent',
              className: 'mb-0',
              items: [
                {
                  content: 'M: ',
                },
                {
                  content: '(909) 328-8797',
                  url: 'tel:+19093288797',
                },
              ],
            },
            {
              id: 'f-3-col-1-item-6',
              type: 'itemContent',
              className: 'mb-0',
              items: [
                {
                  content: 'O: ',
                },
                {
                  content: '(909) 328-8227',
                  url: 'tel:+19093288227',
                },
              ],
            },
            {
              id: 'f-3-col-1-item-7',
              type: 'heading',
              elementType: 'p',
              className: 'mb-0',
              data: 'DRE# 01382495',
            },
            {
              id: 'f-3-col-1-item-8',
              type: 'itemContent',
              className: 'mb-0',
              items: [
                {
                  content: 'M: ',
                },
                {
                  content: '(909) 633-3714',
                  url: 'tel:+19096333714',
                },
              ],
            },
            {
              id: 'f-3-col-1-item-9',
              type: 'itemContent',
              className: '',
              items: [
                {
                  content: 'M: ',
                },
                {
                  content: '(909) 533-6058',
                  url: 'tel:+19095336058',
                },
              ],
            },
            {
              id: 'f-3-col-1-item-10',
              type: 'image',
              className: 'on-dolin-footer-realtor-logo',
              style: { width: '66px', paddingTop: '70px' },
              url: 'https://si-homelight.s3.amazonaws.com/sites/dolin/logo-footer-equal-realtor.png',
            },
          ],
        },
        {
          id: 'f-3-col-2',
          className: 'col-xl-3 col-lg-6 col-md-6 col-sm-12',
          items: [
            {
              id: 'f-3-col-2-heading',
              type: 'heading',
              elementType: 'h1',
              className: '',
              data: 'Resources',
            },
            {
              id: 'f-3-col-2-menu',
              type: 'menu',
              className: 'on-dolin-footer-menu',
              items: [
                {
                  id: 'f-3-col-2-menu-item-1',
                  data: 'Buyers in Long Beach',
                  url: 'https://www.gabbytaylorgroup.com/buyers/',
                },
                {
                  id: 'f-3-col-2-menu-item-3',
                  data: 'Buyers in Redlands',
                  url: 'https://www.gabbytaylorgroup.com/buyers/buyers-in-redlands/',
                },
                {
                  id: 'f-3-col-2-menu-item-3',
                  data: 'Sellers in Long Beach',
                  url: 'https://www.gabbytaylorgroup.com/sellers/',
                },
                {
                  id: 'f-3-col-2-menu-item-4',
                  data: 'Sellers in Redlands',
                  url: 'https://www.gabbytaylorgroup.com/sellers/sellers-in-redlands/',
                },
              ],
            },
          ],
        },
        {
          id: 'f-3-col-3',
          className: 'col-lg-6 col-sm-12 on-dolin-featured-comm-full-screen',
          items: [
            {
              id: 'f-3-col-3-heading',
              type: 'heading',
              elementType: 'h1',
              className: '',
              data: 'Featured Communities',
            },
            {
              id: 'f-3-col-3-list',
              type: 'list',
              className: 'on-dolin-footer-list',
              rowClassName: 'on-dolin-footer-list-row',
              items: [
                {
                  id: 'f-3-col-3-list-row-1',
                  data: [
                    {
                      text: 'Alamitos Heights',
                      url: 'https://www.gabbytaylorgroup.com/contact/alamitos-heights/',
                    },
                    {
                      text: 'Highland',
                      url: 'https://www.gabbytaylorgroup.com/highland/',
                    },
                  ],
                },
                {
                  id: 'f-3-col-3-list-row-2',
                  data: [
                    {
                      text: 'Banning',
                      url: 'https://www.gabbytaylorgroup.com/banning/',
                    },
                    {
                      text: 'Loma Linda',
                      url: 'https://www.gabbytaylorgroup.com/loma-linda/',
                    },
                  ],
                },
                {
                  id: 'f-3-col-3-list-row-3',
                  data: [
                    {
                      text: 'Beaumont',
                      url: 'https://www.gabbytaylorgroup.com/beaumont/',
                    },
                    {
                      text: 'Long Beach',
                      url: 'https://www.gabbytaylorgroup.com/contact/long-beach/',
                    },
                  ],
                },
                {
                  id: 'f-3-col-3-list-row-4',
                  data: [
                    {
                      text: 'Belmont Heights',
                      url: 'https://www.gabbytaylorgroup.com/contact/belmont-heights/',
                    },
                    {
                      text: 'Los Altos',
                      url: 'https://www.gabbytaylorgroup.com/contact/los-altos/',
                    },
                  ],
                },
                {
                  id: 'f-3-col-3-list-row-5',
                  data: [
                    {
                      text: 'Belmont Shore',
                      url: 'https://www.gabbytaylorgroup.com/contact/belmont-shore/',
                    },
                    {
                      text: 'Naples',
                      url: 'https://www.gabbytaylorgroup.com/contact/naples/',
                    },
                  ],
                },
                {
                  id: 'f-3-col-3-list-row-6',
                  data: [
                    {
                      text: 'Bluff Heights',
                      url: 'https://www.gabbytaylorgroup.com/contact/bluff-heights/',
                    },
                    {
                      text: 'Park Estates',
                      url: 'https://www.gabbytaylorgroup.com/contact/park-estates/',
                    },
                  ],
                },
                {
                  id: 'f-3-col-3-list-row-7',
                  data: [
                    {
                      text: 'Bluff Park',
                      url: 'https://www.gabbytaylorgroup.com/contact/bluff-park/',
                    },
                    {
                      text: 'Redlands',
                      url: 'https://www.gabbytaylorgroup.com/redlands/',
                    },
                  ],
                },
                {
                  id: 'f-3-col-3-list-row-8',
                  data: [
                    {
                      text: 'Calimesa',
                      url: 'https://www.gabbytaylorgroup.com/calimesa/',
                    },
                    {
                      text: 'Yucaipa',
                      url: 'https://www.gabbytaylorgroup.com/yucaipa/',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          id: 'f-3-col-4',
          className: 'col-lg-6 col-sm-12 on-dolin-featured-comm-responsive',
          items: [
            {
              id: 'f-3-col-4-heading',
              type: 'heading',
              elementType: 'h1',
              className: '',
              data: 'Featured Communities',
            },
            {
              id: 'f-3-col-4-menu',
              type: 'menu',
              className: 'on-dolin-footer-featured-com-menu',
              items: [
                {
                  id: '',
                  data: 'Alamitos Heights',
                  url: 'https://www.gabbytaylorgroup.com/contact/alamitos-heights/',
                },
                {
                  id: '',
                  data: 'Banning',
                  url: 'https://www.gabbytaylorgroup.com/banning/',
                },
                {
                  id: 'f-3-col-3-list-row-3',
                  data: 'Beaumont',
                  url: 'https://www.gabbytaylorgroup.com/beaumont/',
                },
                {
                  id: 'f-3-col-3-list-row-4',
                  data: 'Belmont Heights',
                  url: 'https://www.gabbytaylorgroup.com/contact/belmont-heights/',
                },
                {
                  id: 'f-3-col-3-list-row-5',
                  data: 'Belmont Shore',
                  url: 'https://www.gabbytaylorgroup.com/contact/belmont-shore/',
                },
                {
                  id: 'f-3-col-3-list-row-6',
                  data: 'Bluff Heights',
                  url: 'https://www.gabbytaylorgroup.com/contact/bluff-heights/',
                },
                {
                  id: 'f-3-col-3-list-row-7',
                  data: 'Bluff Park',
                  url: 'https://www.gabbytaylorgroup.com/contact/bluff-park/',
                },
                {
                  id: 'f-3-col-3-list-row-8',
                  data: 'Calimesa',
                  url: 'https://www.gabbytaylorgroup.com/calimesa/',
                },
                {
                  id: '',
                  data: 'Highland',
                  url: 'https://www.gabbytaylorgroup.com/highland/',
                },

                {
                  id: '',
                  data: 'Loma Linda',
                  url: 'https://www.gabbytaylorgroup.com/loma-linda/',
                },

                {
                  id: '',
                  data: 'Long Beach',
                  url: 'https://www.gabbytaylorgroup.com/contact/long-beach/',
                },

                {
                  id: '',
                  data: 'Los Altos',
                  url: 'https://www.gabbytaylorgroup.com/contact/los-altos/',
                },

                {
                  id: '',
                  data: 'Naples',
                  url: 'https://www.gabbytaylorgroup.com/contact/naples/',
                },

                {
                  id: '',
                  data: 'Park Estates',
                  url: 'https://www.gabbytaylorgroup.com/contact/park-estates/',
                },

                {
                  id: '',
                  data: 'Redlands',
                  url: 'https://www.gabbytaylorgroup.com/redlands/',
                },

                {
                  id: '',
                  data: 'Yucaipa',
                  url: 'https://www.gabbytaylorgroup.com/yucaipa/',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: 'on-dolin-footer-four',
      display: true,
      className: 'on-dolin-footer-section-four',
      sectionClassName: 'on-dolin-footer-section-four-container',
      columns: [
        {
          id: 'f-4-col-1',
          className: 'col-lg-6',
          style: {},
          items: [
            {
              id: 'f-4-col-1-item-1',
              type: 'heading',
              className: '',
              style: { marginBottom: '10px' },
              data: '© Copyright 2022, Gabby Taylor Group. All Rights Reserved.',
            },
            {
              id: 'f-4-col-1-item-2',
              type: 'menu',
              className: 'on-dolin-footer-privacy-menu',
              itemClassName: 'on-dolin-footer-privacy-menu-item',
              style: { marginBottom: '10px' },
              items: [
                {
                  id: 'f-4-col-1-menu-1',
                  data: 'Accessibility',
                  className: 'on-dolin-footer-privacy-menu-item-first',
                  url: 'https://www.gabbytaylorgroup.com/accessibility/',
                },
                {
                  id: 'f-4-l-1',
                  data: '|',
                },
                {
                  id: 'f-4-col-1-menu-2',
                  data: 'Terms of Service',
                  url: 'https://www.gabbytaylorgroup.com/terms-of-service/',
                },
                {
                  id: 'f-4-l-2',
                  data: '|',
                },
                {
                  id: 'f-4-col-1-menu-3',
                  data: 'Privacy Policy',
                  url: 'https://www.gabbytaylorgroup.com/privacy-policy/',
                },
                {
                  id: 'f-4-l-3',
                  data: '|',
                },
                {
                  id: 'f-4-col-1-menu-4',
                  data: 'DMCA Notice',
                  url: 'https://www.gabbytaylorgroup.com/dmca-notice/',
                },
                {
                  id: 'f-4-l-4',
                  data: '|',
                },
                {
                  id: 'f-4-col-1-menu-5',
                  data: 'Property Listings',
                  url: 'https://www.gabbytaylorgroup.com/property-search/site-map/',
                },
                {
                  id: 'f-4-l-5',
                  data: '|',
                },
                {
                  id: 'f-4-col-1-menu-6',
                  data: 'Sitemap',
                  url: 'https://www.gabbytaylorgroup.com/site-map/',
                },
              ],
            },
          ],
        },
        {
          id: 'f-4-col-2',
          className: 'col-lg-6 on-dolin-sierra-column',
          style: {},
          items: [
            {
              id: 'f-4-col-2-item-1',
              type: 'itemContent',
              className: '',
              items: [
                {
                  content: 'Real Estate Websites by ',
                  url: 'https://www.sierrainteractive.com/',
                },
                {
                  className: 'on-dolin-sierra',
                  content: 'Sierra Interactive',
                  url: 'https://www.sierrainteractive.com/',
                },
              ],
            },
          ],
        },
      ],
    },
  ],
  substitute: {},
  replace: {
    'tradeIn.hero.className': 'heroClassName',
    'cashOffer.hero.className': 'heroClassName',
  },
  siteData: {
    agency: 'Long Beach CA Real Estate',
    agentName: 'Gabby Taylor',
    agentDescription: 'Top agents in Long Beach, CA',
    agentAvatar: 'https://si-homelight.s3.amazonaws.com/sites/dolin/agent.jpg',
    heroBackground: 'https://si-homelight.s3.amazonaws.com/sites/dolin/hero-bg.jpg',
    heroClassName: 'on-dolin-hero',
  },
};
